@tailwind base;
@tailwind components;
@tailwind utilities;

/* Checkbox */
input:checked + div {
	@apply border-custom-green;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* Change Autocomplete styles in Chrome*/
@keyframes onAutoFillStart {
	100% {
		opacity: 1;
	}
}

@keyframes onAutoFillCancel {
	100% {
		opacity: 1;
	}
}

input:autofill {
	animation-name: onAutoFillStart;
	-webkit-text-fill-color: theme('colors.custom-green');
	background-clip: text;
	-webkit-background-clip: text;
}

input:not(:autofill) {
	animation-name: onAutoFillCancel;
}

.grid-layaut-agregar-o-m{
	display: grid;
	grid-template-columns: 30% 25% 50% ;
	grid-template-rows: auto 25%;
	justify-content: center;
	width: 100%;
}
.buttonCalculate{
	grid-column: 2 /span 1;
}
.TermSlider-o-m{
	grid-column: 2 / span 2;
	width: 100%;

}
.processFinancingOrden > h1 {
font-size:revert !important;
font-weight:normal;
}
