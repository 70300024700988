.switch {
	position: relative;
	display: inline-block;
	width: 58px;
	height: 28.17px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	@apply bg-custom-light-gray;
}

.slider:before {
	position: absolute;
	content: '';
	height: 22px;
	width: 22px;
	left: 4px;
	bottom: 3px;
	background-color: white;
	background-image: url('../assets/images/header/toggle-switch/sol.png');
	background-position: center;
	background-repeat: no-repeat;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	@apply bg-custom-green;
}

input:checked + .slider:before {
	-webkit-transform: translateX(28px);
	-ms-transform: translateX(28px);
	transform: translateX(28px);
	background-image: url('../assets/images/header/toggle-switch/luna.png');
}

/* Rounded sliders */

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
