@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected:hover {
	@apply bg-custom-purple text-button dark:bg-custom-green;
}

.react-datepicker__month-container {
	@apply bg-light;
}

.react-datepicker__header,
.react-datepicker__year-select,
.react-datepicker__month-select {
	@apply bg-custom-light-gray;
}

.react-datepicker-popper {
	@apply z-20;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	@apply h-full;
}

.react-datepicker__navigation,
.react-datepicker__current-month {
	display: none;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
	@apply rounded-full p-1 font-black text-light shadow-light;
}
